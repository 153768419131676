/*
全局样式，不要轻易编写全局样式
*/

html,
body,
#root {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13px;
}

#nprogress .bar {
    z-index: 9999;
}

.ant-form label,
.ant-form-explain {
    font-size: 13px;    /**全局字体，表单label*/
}
.ant-btn ,
.ant-dropdown-menu-item , .ant-menu,.ant-breadcrumb,
.ant-input ,.ant-form , .ant-form-item,.item-inner,.ant-select,.ant-picker,.ant-popover,
.ant-pagination,.total-count, .ant-table, th , td,
.ant-tabs, .ant-tabs-nav-container,
.ant-checkbox-wrapper,
.ant-modal-confirm-content{
    font-size: 13px !important;
}


.data-modal-overflow .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.data-modal-overflow .ant-modal-body {
    overflow: overlay;
    flex: 1;
}
/*.ant-form-item-label,*/
/*.ant-form-item-control {*/
/*line-height: 25px;*/
/*}*/

/*.ant-tooltip,*/
/*.ant-modal-mask,*/
/*.ant-modal-wrap,*/
/*.ant-message,*/
/*.ant-menu-submenu-popup,*/
/*.ant-notification {*/
/*z-index: 9999 !important;;*/
/*}*/

/*.ant-modal {*/
/*padding-bottom: 84px !important;*/
/*}*/

/* 只在打印时显示 */
.just-print {
    display: none !important;
}

@media print {
    body {
        padding: 0 !important;
        background: none;
    }

    /* 打印时不显示 */
    .no-print {
        display: none !important;
    }

    .just-print {
        display: block !important;
    }

    .ant-message {
        display: none !important;
    }

    .ant-modal-mask {
        display: none !important;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition-delay: 99999s;
    -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
}

.flex {
    display: flex;
    flex-direction: row;
}

.space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.mini-steps.ant-steps-dot .ant-steps-item-tail,
.mini-steps.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    margin: 0 0 0 30px;
}


.mini-steps.ant-steps-dot .ant-steps-item-icon,
.mini-steps.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
    margin-left: 27px;
}

.mini-steps.ant-steps-dot .ant-steps-item-content,
.mini-steps.ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: 100px;
}
.mini-steps.ant-steps-label-vertical .ant-steps-item-content {
    text-align: left;
}
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(100, 100, 100);
    border-radius: 10px;
}
/* 
::-webkit-scrollbar-thumb:active {
    background: rgb(68, 68, 68);
    border-radius: 10px;
} */


.antd-upload-container .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img,
.antd-upload-container-large .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img
{
    object-fit: contain!important;
}

.antd-upload-container-large .ant-upload.ant-upload-select-picture-card,
.antd-upload-container-large .ant-upload-list-picture-card .ant-upload-list-item,
.antd-upload-container-large .ant-upload-list-picture-card-container
 {
    width: 205px;
    height: 205px;
}
